<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems" :dates="[forms.begin, forms.end]">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="2">
            <Date-Picker v-model="forms.begin" label="Fecha inicio" offYear offLimit />
          </v-col>
          <v-col cols="12" md="2">
            <Date-Picker v-model="forms.end" label="Fecha fin" offYear offLimit />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete v-model="forms.user" :items="users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account"
            persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!(forms.begin && forms.end && forms.user)" />
            <Basic-Btn text="Imprimir" color="primary" icon="mdi-printer" @click="downloadReport()" :loading="loading" :disabled="!(forms.begin && forms.end && forms.user && currItems.length)" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="no_empleado" class="elevation-1" single-expand show-expand dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre_empleado`]="{ item }">
          {{ item.nombre_empleado + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.tiempo_retardo`]="{ item }">
          {{ item.tiempo_retardo ? item.tiempo_retardo : "" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <br/>
            <v-row>
              <v-col cols="2">
                <strong>Fecha</strong>
              </v-col>
              <v-col cols="2">
                <strong>Entrada</strong>
              </v-col>
              <v-col cols="2">
                <strong>Inicio comida</strong>
              </v-col>
              <v-col cols="2">
                <strong>Fin comida</strong>
              </v-col>
              <v-col cols="2">
                <strong>Salida</strong>
              </v-col>
            </v-row>
            <template v-for="(val, date) in item.fechas">
              <v-row :key="date">
                <v-col cols="2">
                  <strong>{{ date }}</strong>
                </v-col>
                <v-col cols="2">
                  {{ item.fechas[date].hora_entrada }}
                </v-col>
                <v-col cols="2">
                  {{ item.fechas[date].hora_inicio_comida }}
                </v-col>
                <v-col cols="2">
                  {{ item.fechas[date].hora_fin_comida }}
                </v-col>
                <v-col cols="2">
                  {{ item.fechas[date].hora_salida }}
                </v-col>
              </v-row>
            </template>
            <br/>
          </td>
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components";
import moment from "moment";
import downloadjs from 'downloadjs'

export default {
  name: "record",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    dateSearch: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    headers: [
      { text: "Numero de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre_empleado" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Puesto", align: "left", sortable: true, value: "nombre_puesto" },
      { text: "Departamento", align: "left", sortable: true, value: "nombre_departamento" },
      { text: "Horario", align: "left", sortable: false, value: "nombre_horario" },
      { text: "", align: "left", sortable: false, value: "data-table-expand" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20,
    users: [],
    forms: {
      begin: "",
      end: "",
      user: null
    },
  }),
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    refresh() {
      this.loading = true;
      let data = {
        fecha_inicio: this.forms.begin,
        fecha_fin: this.forms.end,
        usuario: this.forms.user
      };
      this.axios
        .post(services.routes.reports + "/record", { data })
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            if (!this.items[i].idusuario) {
              this.items.splice(i, 1);
            }
          }
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo = this.items[i].nombre_empleado + " " + this.items[i].apellido_paterno + " " + this.items[i].apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros obtenidos correctamente.";
        });
    },
    getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
    getFiltered(values) {
      this.currItems = values
    },
    downloadReport() {
      this.loading = true
      let data = {
        items: this.currItems,
        dates: [this.forms.begin, this.forms.end]
      }
      this.axios({
        url: services.routes.reports + '/record/pdf',
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
      .then((response) => {
        downloadjs(response.data, 'Reporte de Registros.pdf', "application/pdf");
      })
      .catch((error) => {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "El reporte no puede ser generado";
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped>
</style>
